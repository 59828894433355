import phpTimeFormmatter from './phpTimeFormat';

/**
 * @function convertToLocalTime
 * @param  {HTMLTimeElement} element
 * @return {void}
 *
 * Converts a time element's dateTime attribute to a local time string using php format tokens.
 */
export default function convertToLocalTime(element) {
    const { dateTime } = element;
    const date = new Date(dateTime * 1000);

    if (dateTime !== undefined) {
        const { format } = element.dataset;

        if (format) {
            element.textContent = phpTimeFormmatter.format(date, format);
        }
    } else {
        throw new Error('No dateTime attribute found on element.');
    }
}
